import React from "react";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import AppProvider from "./AppProvider";
import { useEffect } from "react";
import axios from "./library/helpers/axios";
import profileActions from "./redux/profile/actions";
import { useDispatch } from "react-redux";

const App = () => {
  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />

          <Routes />
        </>
      </AppProvider>
    </Provider>
  );
};

Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
