// ////////// make action /////////
// const COMPANY="COMPANY";
// const companyAction=()=>{
// return {
//   type,
// }

// }
// //////////////////////////////



const defaultCompany = () => {
  const company = localStorage.getItem("defaultCompany")
    ? JSON.parse(localStorage.getItem("defaultCompany"))
    : false;
  return company;
};
///////////// reducer //////////////
const initState = {
  companies:defaultCompany()||{}
}

export default function companyReducer(state=initState,action){
switch (action.type) {
  case 'COMPANY':return {
...state,
companies:action.payload
  }
  
  default:return state
}
}
