import axios from "axios";
import { store } from "../../redux/store";
// export const URL = "https://app-beta.taakkad.com";
export const URL = "https://back.taakkad.com"; // ==> live
// export const URL = "https://final.taakkad.com"; //dev

const company_id = () => {
  const company = localStorage.getItem("defaultCompany")
    ? JSON.parse(localStorage.getItem("defaultCompany"))
    : false;
  console.log(company);
  return company;
};
const state = store.getState();
const DefaultCompany = state.companyReducer.companies;

const enstance = axios.create({
  baseURL: `${URL}/api`,
  headers: {
    "X-Language": "en",
    "X-company": DefaultCompany && DefaultCompany.id,
  },
});
// let x;
// enstance.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     console.log(config);
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

enstance.interceptors.request.use((req) => {
  // Important: request interceptors **must** return the request.
  return req;
});
enstance.interceptors.response.use(
  (res) => {
    return res;
  },

  (err) => {
    if (err && err.response && err.response.data.message) {
      if (err.response.status == 403 && err.response.config.url != "/login") {
        //   window.localStorage.clear();
        window.location.replace("/");
        window.localStorage.removeItem("defaultCompany");
      } else if (
        err.response.status == 401 &&
        err.response.config.url != "/login"
      ) {
        window.localStorage.clear();
        window.location.replace("/");
      }
    }
    // Important: response interceptors **must** return the response.
    // return err;
    return Promise.reject(err);
  }
);

export default enstance;
