import defaultTheme from "./default";
import customTheme from "./custom";

const themes = {
  defaultTheme,
  customTheme
};

export const themeConfig = {
  topbar: "defaultTheme",
  sidebar: "defaultTheme",
  layout: "defaultTheme",
  theme: "defaultTheme"
};
export default themes;
